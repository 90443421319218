
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import {useI18n} from 'vue-i18n';
import {defineComponent} from 'vue';

export default defineComponent(
    {
      components: {Header, Footer},
      name: 'LinksPage',
      setup() {
        const {t, locale} = useI18n();
        return {t, locale};
      },
      data: function() {
        return {
          scrollPosition: 0,
          screenWidth: 0,
        };
      },
      mounted() {
        window.addEventListener('scroll', this.handleScroll);
      },
      unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
      },
      methods: {
        handleScroll() {
          this.scrollPosition = window.scrollY;
          /* @ts-ignore */
          this.screenWidth = document?.scrollingElement?.clientWidth;
        },
        isFormBuyActive() {
          switch (true) {
            case this.screenWidth <= 320:
              return this.scrollPosition >= 1100 && this.scrollPosition <= 1700;
            case this.screenWidth <= 375:
              return this.scrollPosition >= 1000 && this.scrollPosition <= 1600;
            case this.screenWidth <= 425:
              return this.scrollPosition >= 900 && this.scrollPosition <= 1500;
            case this.screenWidth <= 768:
              return this.scrollPosition >= 500 && this.scrollPosition <= 1200;
            case this.screenWidth <= 1024:
              return this.scrollPosition >= 540 && this.scrollPosition <= 1200;
            case this.screenWidth <= 1440:
              return this.scrollPosition >= 700 && this.scrollPosition <= 1400;
            default:
              return this.scrollPosition >= 700 && this.scrollPosition <= 1500;
          }
        },
        isMerchFormActive() {
          switch (true) {
            case this.screenWidth <= 320:
              return this.scrollPosition >= 2390 && this.scrollPosition <= 3100;
            case this.screenWidth <= 375:
              return this.scrollPosition >= 2290 && this.scrollPosition <= 3000;
            case this.screenWidth <= 425:
              return this.scrollPosition >= 2100 && this.scrollPosition <= 2900;
            case this.screenWidth <= 768:
              return this.scrollPosition >= 1200 && this.scrollPosition <= 1970;
            case this.screenWidth <= 1024:
              return this.scrollPosition >= 1250 && this.scrollPosition <= 2300;
            case this.screenWidth <= 1440:
              return this.scrollPosition >= 1300 && this.scrollPosition <= 2800;
            default:
              return this.scrollPosition >= 1500 && this.scrollPosition <= 2800;
          }
        },
      },
    });
